<template>
  <div class="center">
    <el-form ref="Form" :model="formInline">
      <div class="search">
        <el-form-item prop="name">
          <el-input
            v-model="formInline.name"
            maxlength="20"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="dictId">
          <el-select v-model="formInline.dictId" placeholder="请选择服务类别">
            <el-option
              v-for="item in options"
              :key="item.dictId"
              :label="item.dictValue"
              :value="item.dictId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="bottom" @click="getList">查询</div>
        <div class="bottom resive" @click="resive">重置</div>
      </div>
    </el-form>
    <div class="list">
      <div :key="item.serviceGoodId" class="table" v-for="item in tableList">
        <div class="left pointer" @click="todetail(item.serviceGoodId)">
          <div class="img">
            <el-image style="width: 100%; height: 100%" :src="item.mainGraph" />
          </div>
          <div class="text">
            
              <div class="first f-hide" style="width:400px">{{ item.name }}</div>
            <div class="two">
              <span>{{
                "服务类别：" +
                item.serviceType +
                " | 单价：" +
                item.price +
                item.unit
              }}</span>
            </div>
            <div class="three">
              <i class="hy-icon-shijian1"></i>&nbsp;&nbsp;&nbsp;
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            <span
              v-if="!item.onShelf && item.applyStatus == 1"
              class="status"
              style="background: #909399"
              >{{ "已下架" }}</span
            >
            <span v-else :class="className[item.applyStatus]" class="status">{{
              applyStatus[item.applyStatus]
            }}</span>
          </div>

          <el-button
            v-if="!item.onShelf && item.applyStatus != 0"
            type="primary"
            plain
            size="mini"
            style="width: 104px"
            @click="del(item.serviceGoodId)"
            >删除商品</el-button
          >
          <el-button
            v-if="(item.onShelf && item.applyStatus == 1)||item.applyStatus == 0"
            type="primary"
            plain
            size="mini"
            @click="down(item.serviceGoodId)"
            >下架服务商品</el-button
          >
        </div>
      </div>
    </div>
    <div class="allpage">
      <el-pagination
        background
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  serviceGooddelete,
  onShelf,
  getpartnerList,
  serviceGood,
} from "@/api/home";
const { baseUrl } = require("@/config/index.js");
import * as types from "@/store/action-types";
import Vue from "vue";

export default {
  data() {
    return {
      applyStatus: ["待审批", "审批通过", "审批拒绝"],
      className: ["", "green", "red"],
      total: 0,
      formInline: {
        companyId: "",
        name: "",
        dictId: "",
        pageSize: 4,
        pageNum: 1,
      },
      options: [],
      tableList: [],
    };
  },
  created() {
	this.formInline.companyId = Vue.ls.get(types.enterpriseId);
    this.gettype();
    this.getList();
  },
  methods: {
    resive() {
      this.$refs.Form.resetFields();
      this.getList()
    },
    async getList() {
      const res = await serviceGood(this.formInline);
      if (res.code == 0) {
        this.tableList = res.data.list.map((item) => {
          return {
            ...item,
            price: item.price ? item.price+'元' : "面议",
            unit: item.price && item.unit?'/'+item.unit:'',
            mainGraph: baseUrl + "third/third/ossGetFile/" + item.mainGraph,
            createTime: item.createTime
              ? item.createTime.replace("T", " ")
              : "",
          };
        });
        this.total = res.data.total;
      }
    },
    async gettype() {
      const res = await getpartnerList({
        dictType: "service_range",
        pageSize: 999,
        pageNum: 1,
      });
      if (res.code == 0) {
        this.options = res.data.list;
      }
    },
    del(id) {
      if (!this.beforhandle('personalCenter:publishedServices:del')) return;
      this.$confirm("确定要删除选中的商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zeee-confirm",
      })
        .then(() => {
          serviceGooddelete({ serviceGoodIdList: [id] }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    down(id) {
      if (!this.beforhandle('personalCenter:publishedServices:offshelf')) return;
      this.$confirm("确定要下架选中的商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zeee-confirm",
      })
        .then(() => {
          onShelf({ onShelf: false, serviceGoodIdList: [id] }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "下架成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消下架",
          });
        });
    },
    handleCurrentChange(value) {
      this.formInline.pageNum = value;
      this.getList();
    },
    todetail(id) {
      if (!this.beforhandle('personalCenter:publishedServices:detail')) return;
      this.$router.push({
        path: "/myPublisheddetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.zeee-confirm {
  .el-message-box {
    width: 450px !important;
    height: 218px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 119px;
    color: #606266;
    font-size: 14px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
<style lang="scss" scoped >
::v-deep .el-form-item {
  margin-bottom: 0;
  margin-left: 10px;
}
::v-deep .el-input__inner {
  width: 211px !important;
  height: 34px !important;
}
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    border-bottom: 1px solid #e5e5e5;
    .bottom {
      margin-left: 10px;
      height: 34px;
      text-align: center;
      padding: 0px 20px;
      border-radius: 4px;
      cursor: pointer;
      color: white;
      line-height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
    .resive {
      background: white;
      color: #aaaaaa;
      border: 1px solid #ccc;
    }
  }
  .list {
    padding: 16px;
    padding-bottom: 0px;
    .table {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 110px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 16px;
      margin-bottom: 16px;
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
          overflow: hidden;
        }
        .text {
          margin-left: 17px;
          .first {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
            margin-bottom: 13px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 800px;
          }
          .two {
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
            margin-bottom: 35px;
          }
          .three {
            display: flex;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 93px;
        .status {
          padding: 4px 13px;
          height: 19px;
          line-height: 19px;
          font-size: 12px;
          color: #fff;
          background: #f87b28;
          border-radius: 10px;
        }
        .green {
          background: #67c23a !important;
        }
        .red {
          background: #f56c6c !important;
        }
        .do {
          width: 127px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #b3d8ff;
          border-radius: 4px;
        }
      }
    }
  }
  .allpage {
    text-align: right;
    margin-bottom: 50px;
    margin-right: 6px;
  }
}
</style>>